import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { stateToHTML } from 'draft-js-export-html';
import { IContents } from '../../types/content/contentTypes';
import { v4 as uuidv4 } from 'uuid';
import {
  BundleEntry,
  CatalogEntry,
  CodeableConcept,
  Composition,
  CompositionRelatesTo,
  CompositionSection,
  Reference,
} from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';

const sourceContentId = uuidv4();

export const useCreateUpdateContent = (
  data: any,
  options?: Omit<UseMutationOptions<any, unknown, any>, 'mutationFn' | 'mutationKey'>
): UseMutationResult<any> => {
  const medplum = useMedplum();
  const projectId = medplum.getProject()?.id;
  const projectName = medplum.getProject()?.name;

  return useMutation(async ({ coverImageUrl }) => {
    const coverImageSection: any = {
      code: {
        id: 'cover-image',
      },
      text: {
        div: "<img src='" + coverImageUrl + "' alt='cover-image' />",
        status: 'final',
      },
      entry: [
        {
          display: coverImageUrl,
        },
      ],
    };

    const contents = data?.contents?.map((content: IContents) => {
      return {
        ...content,
        value: stateToHTML(content.content.getCurrentContent()),
      };
    });

    const figuresArray = contents.map((c: any) => {
      return {
        ...c,
        value: c.value
          .split('<figure>')
          .map((p: any) => (p.includes('</figure>') ? '<figure>' + p : p))
          .map((p: any) => p.split('</figure>').map((p: any) => (p.includes('<figure>') ? p + '</figure>' : p)))
          .flat()
          .map((p: any) => p.split('<p><br></p>').join('<p><br>&zwnj;</p>')),
      };
    });

    const compositions: Composition[] = figuresArray.map((f: any) => {
      const compositionSections: CompositionSection[] = f?.value?.map((x: any) => {
        const isImage = x.includes('<figure>');
        return {
          code: {
            id: isImage ? 'image' : 'text',
          },
          text: {
            div: x,
            status: 'final',
          },
          ...(isImage && {
            entry: [
              {
                display: x?.split('src="')?.[1]?.split('"')?.[0],
              },
            ],
          }),
        };
      });

      compositionSections.unshift(coverImageSection);

      const relatesToForNewLang: CompositionRelatesTo[] = [
        {
          code: 'appends',
          targetReference: {
            id: data.formType === 'create' ? `urn:uuid:${sourceContentId}` : data.contentId,
            reference: `${
              data.formType === 'create' ? `urn:uuid:${sourceContentId}` : `Composition/${data.contentId}`
            }`,
          },
        },
      ];

      const category: CodeableConcept[] = [
        {
          coding: [
            {
              code: 'language',
              display: f.language.value,
            },
          ],
          text: f.language.value,
        },
      ];

      const eventArr: Reference<CatalogEntry>[] = data.tags.map((tag: any) => {
        return {
          resourceType: 'CatalogEntry',
          id: tag.value,
        };
      });

      return {
        resourceType: 'Composition',
        id: f.id ?? undefined,
        title: f.title,
        text: {
          div: f.description,
          status: 'generated',
        },
        section: compositionSections,
        status: f.status,
        language: f.language.value,
        category, // To keep the language data
        author: [
          {
            id: projectId,
            resourceType: 'Project',
            display: projectName,
          },
        ],
        subject: {
          reference: `List/${data.categoryId}`,
        },
        type: {
          id: f.isSource ? 'source' : 'related',
          text: f.isSource ? 'Source' : 'Related',
        },
        relatesTo: f.isSource ? undefined : relatesToForNewLang,
        date: new Date().toISOString(),
        identifier: {
          value: 'content',
        },
        confidentiality: 'N',
        event: eventArr,
      };
    });

    const bundleEntries: BundleEntry<Composition>[] = compositions.map((x: Composition) => {
      const generateFullUrl = (): string => {
        if (data.formType === 'create') {
          return `urn:uuid:${sourceContentId}`;
        }

        if (data.formType === 'update' && x.id) {
          return `/Composition/${x.id}`;
        } else {
          return '';
        }
      };

      return {
        fullUrl: generateFullUrl(),
        request: {
          method: data.formType === 'update' && x.id ? 'PUT' : 'POST',
          url: data.formType === 'update' && x.id ? `/Composition/${x.id}` : '/Composition',
        },
        resource: {
          ...x,
        },
      };
    });

    return medplum.executeBatch({
      resourceType: 'Bundle',
      type: 'transaction',
      entry: bundleEntries,
    });
  }, options);
};

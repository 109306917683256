import { Box, Card, Checkbox, Grid, HoverCard, Image, Text, Title } from '@mantine/core';
import React from 'react';
import { useContentStore } from '../store/content/useContentStore';
import { useGetContents } from '../queries/content/useGetContents';
import { Composition } from '@medplum/fhirtypes';
import { contentEditorSourceLang } from '../utils/content/contentUtils';

interface Props {
  activeTab: string | null;
  onClick: (id: string) => void;
}

const ContentGrid: React.FC<Props> = ({ activeTab, onClick }) => {
  const searchText = useContentStore((s) => s.searchText);
  const statusValue = useContentStore((s) => s.status);

  const { data: contentsData } = useGetContents({
    categoryId: activeTab ?? '',
    filter: {
      status: statusValue,
      search: searchText,
    },
  });

  const { selectedContents, setSelectedContents } = useContentStore((x) => ({
    selectedContents: x.selectedContents,
    setSelectedContents: x.setSelectedContents,
  }));

  const toggleSelectedContent = (e: React.ChangeEvent<HTMLInputElement>, id: string): void => {
    e.stopPropagation();
    if (selectedContents.includes(id)) {
      const findRelatedContents = contentsData
        ?.filter((x: Composition) => x?.language !== contentEditorSourceLang.value)
        ?.filter((y: any) => y?.relatesTo?.[0]?.targetReference?.id === id);

      const relatedIds = (findRelatedContents?.map((x: Composition) => x?.id) as string[]) ?? [];
      const ids = [id, ...relatedIds];
      setSelectedContents(selectedContents.filter((x) => !ids.includes(x)));
    } else {
      const findRelatedContents = contentsData
        ?.filter((x: Composition) => x?.language !== contentEditorSourceLang.value)
        ?.filter((y: any) => y?.relatesTo?.[0]?.targetReference?.id === id);

      const relatedIds = (findRelatedContents?.map((x: Composition) => x?.id) as string[]) ?? [];
      const ids = [id, ...relatedIds];
      setSelectedContents([...selectedContents, ...ids]);
    }
  };

  const sourceContents = React.useMemo(
    () => contentsData?.filter((x: any) => x?.language === contentEditorSourceLang.value),
    [contentsData]
  );

  return (
    <Box px={12}>
      <Title order={4} mb={12}>
        Contents
      </Title>
      {sourceContents?.length === 0 && (
        <Text variant="text" color="grey">
          No contents found.
        </Text>
      )}
      <Grid>
        {sourceContents?.map((content: Composition) => {
          const coverImageUrl = content?.section?.find((x) => x?.code?.id === 'cover-image')?.entry?.[0]?.display;

          return (
            <Grid.Col span={{ xs: 12, sm: 6, md: 3, xl: 2 }}>
              <HoverCard key={content?.id} shadow="md" position="top" openDelay={1000}>
                <HoverCard.Target>
                  <Card
                    withBorder
                    key={content?.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      cursor: 'pointer',
                      width: 150,
                      overflow: 'hidden',
                      p: 2,
                    }}
                    onClick={() => onClick(String(content?.id))}
                  >
                    <Checkbox
                      checked={selectedContents?.includes(String(content?.id))}
                      onChange={(e) => toggleSelectedContent(e, String(content?.id))}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        alignSelf: 'flex-start',
                        marginBottom: 8,
                      }}
                    />
                    <Image
                      src={coverImageUrl}
                      width={100}
                      height={100}
                      alt="cover image"
                      style={{
                        borderRadius: 20,
                      }}
                    />
                    <Text>{content?.title?.substring(0, 15)}...</Text>
                  </Card>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm" fw="bold">
                    {content?.title}
                  </Text>
                  <Text size="sm">{content?.text?.div}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Grid.Col>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ContentGrid;

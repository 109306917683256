import { Container, Grid, Group, Paper, Title, Text } from '@mantine/core';
import { MedplumLink } from '@medplum/react';

import { MemberTable } from './admin/MembersTable';

export function Developer(): JSX.Element {
  return (
    <Container size="lg">
      <Grid gutter="xl">
        <div>
          <Paper mt={100} shadow="lg" style={{ padding: '30px' }}>
            <Title mb={20} mt={10} size={23}>
              {' '}
              {'Overview'}
            </Title>

            <Text>
              {
                'Welcome to the Ovok developer dashboard. It is designed for developers and project administrators. Use it to manage projects, handle user accounts, and examine resources as well as to configure credentials, automations, and others.'
              }
            </Text>

            <Text mt={'lg'}>
              {'For development, see here for the'}{' '}
              <a
                style={{ textDecoration: 'underline', color: '#000' }}
                href="https://docs.ovok.com/reference"
                target="_blank"
              >
                {'API Documentation'}
              </a>
              ,{' '}
              <a
                style={{ textDecoration: 'underline', color: '#000' }}
                href="https://docs.ovok.com/docs"
                target="_blank"
              >
                {'Guides'}
              </a>
              , {'and'}{' '}
              <a
                style={{ textDecoration: 'underline', color: '#000' }}
                href="https://docs.ovok.com/docs"
                target="_blank"
              >
                {'Examples'}
              </a>
              .
            </Text>

            <Title mt={40} mb={5} size={18}>
              {'Create your API keys here'}
            </Title>
            <MemberTable resourceType="ClientApplication" fields={['user', 'admin', '_lastUpdated']} />

            <Group>
              <MedplumLink to={`/admin/clients/new`}>{'Create new client'}</MedplumLink>
            </Group>
          </Paper>
        </div>
      </Grid>
    </Container>
  );
}

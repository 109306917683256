import * as React from 'react';
import { Card, Flex, Image, Text, Title, useMantineTheme } from '@mantine/core';

interface UploadImageProps {
  imageUrl?: string;
  errorText?: string;
  onChange: (file: File) => void;
}

export const UploadImage: React.FC<UploadImageProps> = ({ imageUrl, errorText, onChange }) => {
  const theme = useMantineTheme();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    await handleUpload(file);

    e.target.value = '';
  };

  const handleUpload = async (file: File): Promise<void> => {
    onChange(file);
  };

  const handleOpenSelector = (): void => {
    inputRef.current?.click();
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer?.files?.[0]) {
      await handleUpload(e.dataTransfer.files[0]);
    }
  };

  return (
    <Card withBorder shadow="lg">
      <Flex p={4} direction="column" justify="center">
        <Title order={4} mb={12}>
          Cover image
        </Title>

        <Flex
          justify="center"
          align="center"
          onClick={handleOpenSelector}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          style={{ cursor: 'pointer' }}
        >
          {imageUrl ? (
            <Flex>
              <Image src={imageUrl} alt="" width={228} height={202} />
            </Flex>
          ) : (
            <Flex
              direction="column"
              align="center"
              justify="center"
              p={12}
              style={{
                width: '100%',
                border: `1px dashed ${errorText ? theme.colors.red[6] : theme.colors.blue[6]}`,
                borderRadius: 8,
              }}
            >
              <img src="../../../static/img/dragdrop.png" alt="" width={228} height={202} />
              <Text mt={4} size="sm">
                Drag and drop file here or browse
              </Text>
            </Flex>
          )}

          {errorText && (
            <Text mt={2} size="md" color="error">
              {errorText}
            </Text>
          )}

          <input ref={inputRef} type="file" accept="image/*" style={{ display: 'none' }} onChange={handleChange} />
        </Flex>

        {!imageUrl && (
          <Text mt={4} size="xs">
            * Required
          </Text>
        )}
      </Flex>
    </Card>
  );
};

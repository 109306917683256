import { Box, Checkbox, Table, Title } from '@mantine/core';
import React from 'react';
import { useContentStore } from '../store/content/useContentStore';
import { useGetContents } from '../queries/content/useGetContents';
import { contentEditorSourceLang } from '../utils/content/contentUtils';

interface Props {
  activeTab: string | null;
  onClick: (id: string) => void;
}

const ContentTable: React.FC<Props> = ({ activeTab, onClick }) => {
  const searchText = useContentStore((s) => s.searchText);
  const statusValue = useContentStore((s) => s.status);

  const { data: contentsData } = useGetContents({
    categoryId: activeTab ?? '',
    filter: {
      status: statusValue,
      search: searchText,
    },
  });

  const { selectedContents, setSelectedContents } = useContentStore((x) => ({
    selectedContents: x.selectedContents,
    setSelectedContents: x.setSelectedContents,
  }));

  const sourceContents = React.useMemo(
    () => contentsData?.filter((x: any) => x?.language === contentEditorSourceLang.value),
    [contentsData]
  );

  const rows = sourceContents?.map((c: any) => (
    <tr key={c?.id} style={{ backgroundColor: selectedContents?.includes(c?.id as string) ? 'grey' : 'white' }}>
      <td>
        <Checkbox
          aria-label="Select row"
          checked={selectedContents?.includes(c?.id as string)}
          onChange={(event) =>
            setSelectedContents(
              event.currentTarget.checked
                ? [...selectedContents, c?.id as string]
                : selectedContents.filter((position) => position !== (c?.id as string))
            )
          }
        />
      </td>
      <td onClick={() => onClick(c?.id as string)}>{c?.title}</td>
      <td>{c?.date}</td>
    </tr>
  ));

  return (
    <Box px={12}>
      <Title order={4} mb={8}>
        Contents
      </Title>
      <Table highlightOnHover withTableBorder withColumnBorders striped width="100%" verticalSpacing="sm">
        <thead>
          <tr>
            <th />
            <th>Name</th>
            <th>Last modified</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Box>
  );
};

export default ContentTable;

import * as React from 'react';
import { ContentStatusType } from '../types/content/contentTypes';
import { Select } from '@mantine/core';

interface LanguageStatusSelectionProps {
  isCreating?: boolean;
  value: ContentStatusType;
  disabled?: boolean;
  onChange: (value: ContentStatusType) => void;
}

export const LanguageStatusSelection: React.FC<LanguageStatusSelectionProps> = ({ disabled, value, onChange }) => {
  const statusList = React.useMemo(
    () => [
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Inactive',
        value: 'inactive',
      },
      {
        label: 'Draft',
        value: 'draft',
      },
    ],
    []
  );

  return (
    <Select
      disabled={disabled}
      size="sm"
      value={value}
      onChange={(value) => onChange(value as ContentStatusType)}
      placeholder="Status"
      data={
        statusList?.map((f) => {
          return { value: f?.value, label: f?.label };
        }) ?? []
      }
    />
  );
};

import * as React from 'react';
import { MultiSelect } from '@mantine/core';
import { useContentStore } from '../store/content/useContentStore';
import { useGetTagsByName } from '../queries/content/useGetTagsByName';

interface TagSelectionProps {
  errorText?: string;
  disabled: boolean;
}

export const TagSelection: React.FC<TagSelectionProps> = ({ disabled }) => {
  // const queryClient = useQueryClient();

  const [searchValue, onSearchChange] = React.useState('');

  const values = useContentStore((s) => s.values);
  // const setValues = useContentStore((s) => s.handleSetValues);

  const { data: tagsData } = useGetTagsByName();

  // const { mutateAsync: createTag } = useCreateTag({
  //   onSuccess: async (resp) => {
  //     await queryClient.fetchQuery(QueryKeys.GET_TAGS_BY_NAME);
  //     const data: CatalogEntry = resp?.data;
  //
  //     setValues({
  //       ...values,
  //       tags: [...values.tags, { value: data?.id as string, label: data?.type?.coding?.[0]?.display as string }],
  //     });
  //   },
  // });

  // const handleCreateTag = async (newTag: string): Promise<void> => {
  //   const isNewTagExists = values?.tags?.find((t) => t?.label === newTag);
  //   if (isNewTagExists) {
  //     return;
  //   }
  //
  //   await createTag({ title: newTag });
  // };
  //
  // const handleAddItem = (tag: { value: string; label: string }): void => {
  //   const isTagExists = values?.tags?.find((t) => t?.value === tag?.value);
  //   if (isTagExists) {
  //     return;
  //   }
  //
  //   setValues({ ...values, tags: [...values.tags, tag] });
  // };

  // function Value({ label, onRemove }: MultiSelectValueProps & { value: string }): any {
  //   return (
  //     <div>
  //       <Box
  //         style={(theme) => ({
  //           display: 'flex',
  //           cursor: 'default',
  //           alignItems: 'center',
  //           backgroundColor: theme.primaryColor,
  //           border: `${rem(1)} solid ${theme.primaryColor}`,
  //           paddingLeft: theme.spacing.xs,
  //           borderRadius: theme.radius.sm,
  //         })}
  //       >
  //         <Box style={{ lineHeight: 1, fontSize: rem(12) }}>{label}</Box>
  //         <CloseButton onMouseDown={onRemove} variant="transparent" size={22} iconSize={14} tabIndex={-1} />
  //       </Box>
  //     </div>
  //   );
  // }
  //
  // const Item = React.forwardRef<HTMLDivElement, SelectItemProps>(({ label, value }, ref) => {
  //   return (
  //     <div ref={ref}>
  //       <Flex onClick={() => handleAddItem({ value: value as string, label: label as string })} align="center">
  //         <div>{label}</div>
  //       </Flex>
  //     </div>
  //   );
  // });

  return (
    <MultiSelect
      size="sm"
      data={tagsData ?? []}
      disabled={disabled}
      // valueComponent={Value}
      // itemComponent={Item}
      searchable
      // creatable
      clearable
      searchValue={searchValue}
      onSearchChange={onSearchChange}
      // getCreateLabel={(query) => `+ Create ${query}`}
      // onCreate={(query): any => handleCreateTag(query)}
      value={values.tags?.map((t) => t?.value)}
      placeholder="Tags"
      nothingFoundMessage="No tags found"
    />
  );
};

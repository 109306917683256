import * as React from 'react';
import { EditorState } from 'draft-js';
import { Card, Flex, Text, Title, useMantineTheme } from '@mantine/core';
import DraftJsEditor from './DraftJsEditor';

interface ContentEditorProps {
  maxCharacterLimit: number;
  isSource: boolean;
  selectedLanguage: string;
  value: EditorState;
  onChange: (content: EditorState, language: string) => void;
}

export const ContentEditor: React.FC<ContentEditorProps> = ({
  maxCharacterLimit,
  selectedLanguage,
  value,
  onChange,
}) => {
  const theme = useMantineTheme();

  return (
    <Card shadow="xl" withBorder style={{ overflow: 'visible' }}>
      <Flex p={4} direction="row" align="center" justify="space-between">
        <Title order={4}>Content</Title>

        <Flex direction="column" align="flex-end" justify="flex-end">
          <Text variant="body2" color={theme.primaryColor}>
            Content character count: {value.getCurrentContent().getPlainText().length}
          </Text>
          <Text variant="body2" color={theme.colors.red[6]}>
            Max content character limit: {maxCharacterLimit}
          </Text>
        </Flex>
      </Flex>
      <DraftJsEditor
        editorState={value}
        language={selectedLanguage}
        onEditorStateChange={(content: EditorState, language: string) => onChange(content, language)}
      />
    </Card>
  );
};

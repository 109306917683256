import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { QueryKeys } from '../query-keys';
import { useMedplum } from '@medplum/react';

export const useDeleteComposition = (
  options?: UseMutationOptions<unknown, Error, string>
): UseMutationResult<unknown, Error, string> => {
  const queryClient = useQueryClient();
  const medplum = useMedplum();

  return useMutation<unknown, Error, string>(
    async (compositionId: string) => medplum.deleteResource('Composition', compositionId),

    {
      ...options,
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_CONTENT_DETAIL);
      },
    }
  );
};

import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { QueryKeys } from '../query-keys';
import { CatalogEntry } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';

const tagKeys = {
  all: [QueryKeys.GET_TAGS_BY_ID] as const,
  byParams: (ids: string[]) => [...tagKeys.all, ids],
};

export const useGetTagsByIds = (
  ids: string[],
  options?: Omit<UseQueryOptions<any, any, any, any>, 'mutationFn' | 'mutationKey'>
): UseQueryResult<any> => {
  const medplum = useMedplum();
  return useQuery(
    tagKeys.byParams(ids),
    async () => {
      const idsStr = ids.join(',');
      return medplum.searchResources('CatalogEntry', { _id: idsStr }).then((res) => mapToResult(res));
    },
    options
  );
};

const mapToResult = (data: any): any => {
  return data?.map((item: CatalogEntry) => ({
    value: item?.id,
    label: item?.type?.coding?.[0]?.display ?? '',
  }));
};

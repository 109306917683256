import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { QueryKeys } from '../query-keys';
import { useMedplum } from '@medplum/react';
import { Composition } from '@medplum/fhirtypes';
import { ContentStatusType } from '../../types/content/contentTypes';

interface GetContentData {
  categoryId?: string;
  filter: {
    status: ContentStatusType;
    search: string;
  };
}

export const useGetContents = (
  data: GetContentData,
  options?: UseQueryOptions<Composition[], Error>
): UseQueryResult<any> => {
  const medplum = useMedplum();
  return useQuery<Composition[], Error>(
    [QueryKeys.GET_CONTENTS, data.categoryId, data.filter.status, data.filter.search],
    async () =>
      medplum.searchResources('Composition', {
        subject: `List/${data.categoryId}`,
        title: data.filter.search ?? '',
        ...(data.filter.status !== ContentStatusType.ALL && { status: data.filter.status }),
        _sort: '-_lastUpdated',
      }),
    {
      ...options,
      enabled: !!data.categoryId,
      retry: false,
    }
  );
};

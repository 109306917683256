import React from 'react';
import { useQueryClient } from 'react-query';
import { ContentSchema, ContentStatusType } from '../types/content/contentTypes';
import { QueryKeys } from '../queries/query-keys';
import { ContentForm } from './ContentForm';
import { useMedplum } from '@medplum/react';
import { Binary } from '@medplum/fhirtypes';
import { useContentStore } from '../store/content/useContentStore';
import { useGetContentDetail } from '../queries/content/useGetContentDetail';
import { contentEditorSourceLang, targetLangs } from '../utils/content/contentUtils';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { LoadingOverlay } from '@mantine/core';
import { useCreateUpdateContent } from '../queries/content/useCreateUpdateContent';
import { useGetTagsByIds } from '../queries/content/useGetTagsByIds';
import { showNotification } from '@mantine/notifications';
import { t } from 'i18next';

interface Props {}

const EditContentPage: React.FC<Props> = () => {
  const medplum = useMedplum();
  const queryClient = useQueryClient();
  const values = useContentStore((s) => s.values);
  const setValues = useContentStore((s) => s.handleSetValues);

  const { mutateAsync: updateContent, isLoading: isUpdateContentLoading } = useCreateUpdateContent(values, {
    onSuccess: async () => {
      await queryClient.fetchQuery(QueryKeys.GET_CONTENT_DETAIL);
      await queryClient.invalidateQueries(QueryKeys.GET_CONTENTS);
      setSelectedLanguage(contentEditorSourceLang);
      showNotification({ color: 'green', message: t('success') });
    },
    onError: (err: any) => {
      console.error(err);
    },
  });

  const { data: contentDetail } = useGetContentDetail();

  const ids = React.useMemo(
    () => contentDetail?.map((item: any) => item?.tags?.map((x: any) => x?.value)) ?? [],
    [contentDetail]
  );

  const { data: tagsData } = useGetTagsByIds(ids, {
    enabled: contentDetail !== undefined,
    onSuccess: (resp) => {
      setValues({
        ...values,
        tags: resp?.map((x: any) => {
          return { value: x?.value, label: x?.label };
        }),
      });
    },
  });

  const parsedSection: any = React.useMemo(() => {
    return contentDetail
      ?.map((content: any) => {
        const lang = content?.language;
        const contentStatus = content?.status;

        let mergedString = '';
        const deleteCoverImage = content?.section?.filter((obj: any) => obj?.code?.id !== 'cover-image');

        deleteCoverImage?.forEach((obj: any) => {
          if (obj) {
            mergedString += obj?.text?.div;
          }
        });

        const contentState = stateFromHTML(mergedString);

        const languageLabel = targetLangs.find((x) => x.value === lang)?.label ?? lang;

        return {
          id: content?.id ?? '',
          isSource: lang === contentEditorSourceLang.value,
          language: { label: languageLabel, value: lang ?? '' },
          languageLabel: languageLabel,
          title: content.title ?? '',
          description: content?.description ?? '',
          content: contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty(),
          status: contentStatus ?? ContentStatusType.DRAFT,
        };
      })
      .sort((a: any) => (a.isSource ? -1 : 1));
  }, [contentDetail]);

  const findSourceContent = React.useMemo(
    () => contentDetail?.find((content: any) => content?.language === contentEditorSourceLang.value),
    [contentDetail]
  );

  React.useEffect(() => {
    if (!findSourceContent) {
      return;
    }
    if (!parsedSection) {
      return;
    }
    if (!tagsData) {
      return;
    }
    setValues({
      ...values,
      contentId: findSourceContent?.id ?? '',
      contents: parsedSection,
      categoryId: findSourceContent?.categoryId ?? '',
      returnedCoverImgUrl: findSourceContent?.coverImage ?? '',
      status: findSourceContent?.status ?? ContentStatusType.DRAFT,
      formType: 'update' as ContentSchema['formType'],
    });
  }, [findSourceContent, tagsData]);

  const [selectedLanguage, setSelectedLanguage] = React.useState<{ label: string; value: string }>(
    contentEditorSourceLang
  );

  const handleUploadCoverImage = async (file: File): Promise<string | undefined> => {
    const binary: Binary = await medplum.createBinary(file, file.name, file.type);

    return binary.url as string;
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      const coverImageUrl =
        values.returnedCoverImgUrl ?? (await handleUploadCoverImage(values?.coverImageFile as File));

      await updateContent({ coverImageUrl });
    } catch (error) {
      console.error({ error });
    }
  };

  if (isUpdateContentLoading) {
    return <LoadingOverlay visible={isUpdateContentLoading} overlayProps={{ blur: 2 }} />;
  }

  return (
    <ContentForm
      onSubmit={handleSubmit}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
    />
  );
};

export default EditContentPage;

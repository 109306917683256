import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { QueryKeys } from '../query-keys';
import { useMedplum } from '@medplum/react';

export interface IDeeplTranslationResponse {
  translations: {
    detectedSourceLang: string;
    text: string;
  }[];
}

export interface IDeeplTranslationBody {
  text: string[];
  options?: {
    tagHandling?: 'xml' | 'html';
    splitSentences?: 'default' | 'on' | 'off' | 'nonewlines'; //Specifies how input translation text should be split into sentences.
    preserveFormatting?: boolean; //Set to true to prevent the translation engine from correcting some formatting aspects, and instead leave the formatting unchanged, default is false.
    formality?: 'less' | 'more' | 'default' | 'prefer_less' | 'prefer_more'; //Controls whether translations should lean toward formal or informal language
    glossary?: { [key: string]: string };
    outlineDetection?: boolean; // Set to false to disable automatic tag detection, default is true.
    context?: string; // Specifies additional context to influence translations, that is not translated itself. Note this is an alpha feature: it may be deprecated at any time, or incur charges if it becomes generally available. See the API documentation for more information and example usage.
    splittingTags?: string | string[]; //List of XML tags that should be used to split text into sentences
    nonSplittingTags?: string | string[]; //List of XML tags that should not be used to split text into sentences.
    ignoreTags?: string | string[]; //List of XML tags containing content that should not be translated.
  };
  targetLanguage: string;
  sourceLanguage: string;
}

interface IParams {
  body: IDeeplTranslationBody;
}

export const useGetDeeplTranslation = (
  options?: Omit<UseMutationOptions<IDeeplTranslationResponse, any, IParams, any>, 'mutationFn' | 'mutationKey'>
): UseMutationResult<IDeeplTranslationResponse, any, IParams, any> => {
  const medplum = useMedplum();

  return useMutation(
    [QueryKeys.TRANSLATE_HTML],
    async ({ body }) => {
      const translateBody = async (): Promise<any> => {
        return fetch(`https://api.dev.ovok.com/translation`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${medplum.getAccessToken()}`,
          },
        }).then((res) => res.json());
      };

      return translateBody()?.then((data) => {
        return data;
      });
    },
    options
  );
};

import * as React from 'react';
import { useGetContentCategories } from '../queries/content/useGetContentCategories';
import { Select } from '@mantine/core';

interface CategorySelectionProps {
  value: string;
  errorText: string;
  disabled: boolean;
  onChange: (value: string | null) => void;
}

export const CategorySelection: React.FC<CategorySelectionProps> = ({ value, disabled, errorText, onChange }) => {
  const { data: categoriesData } = useGetContentCategories({ retry: false, refetchOnWindowFocus: false });

  return (
    <Select
      disabled={disabled}
      size="sm"
      value={value}
      onChange={onChange}
      placeholder="Pick a category*"
      error={!!errorText}
      ml={8}
      data={
        categoriesData?.map((f: any) => {
          return { value: f?.id ?? '', label: f?.title ?? '' };
        }) ?? []
      }
    />
  );
};

import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { QueryKeys } from '../query-keys';
import { List } from '@medplum/fhirtypes';
import { ListCode } from '../../types/list/listTypes';
import { ProfileResource } from '@medplum/core';
import { useMedplum } from '@medplum/react';

interface CreateContentFolderData {
  title: string;
  profile: ProfileResource | undefined;
  parentListId?: string;
}

export const useCreateContentFolder = (
  options?: Omit<UseMutationOptions<any, Error, any>, 'mutationFn' | 'mutationKey'>
): UseMutationResult<any, Error, CreateContentFolderData, any> => {
  const queryClient = useQueryClient();
  const medplum = useMedplum();
  const projectId = medplum.getProject()?.id;

  return useMutation(async (data: CreateContentFolderData) => {
    const body: List = {
      title: data.title,
      entry: [],
      orderedBy: {
        coding: [
          {
            code: 'entry-date',
            system: 'http://terminology.hl7.org/CodeSystem/list-order',
          },
        ],
      },
      mode: 'working',
      resourceType: 'List',
      source: {
        reference: `Project/${projectId}`,
      },
      code: {
        text: 'File explorer - Content',
        coding: [
          {
            code: '/contents',
            display: 'File explorer for contents',
          },
          {
            code: slugify(data.title),
            display: 'Category name for contents',
          },
        ],
      },
      identifier: [
        {
          system: 'category',
          value: 'category',
        },
      ],
      status: 'current',
    };

    return medplum.createResource(body).then(async (res: any) => {
      if (!data.parentListId) {
        return;
      }

      const parentListArray = await queryClient.getQueryData<List[]>([
        QueryKeys.GET_LIST_ITERATION,
        projectId,
        ListCode.CONTENTS,
      ]);

      const parentList = parentListArray?.find((x) => x.id === data.parentListId);

      if (!parentList) {
        return;
      }

      const parentEntry = [
        ...(parentList.entry ?? []),
        {
          date: new Date().toISOString(),
          flag: {
            coding: [
              {
                display: 'Sub Folder',
              },
            ],
          },
          item: {
            display: data.title,
            id: res.id ?? '',
            resourceType: 'List',
          },
        },
      ];

      await medplum.updateResource({
        ...parentList,
        id: parentList.id,
        entry: parentEntry,
      });
    });
  }, options);
};

function slugify(title: string): string {
  return title.replace(/\s+/g, '-').toLowerCase();
}

import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { Content, IFhirResponse, ISection } from '../../types/content/contentTypes';
import * as yup from 'yup';
import { t } from 'i18next';

export const mapToResult = (contents: any): Content[] | Content => {
  if (Array.isArray(contents)) {
    return contents.map((data: IFhirResponse) => returner(data));
  }

  return returner(contents);
};

const returner = (data: IFhirResponse): Content => {
  const sections = data.section.filter((s: ISection) => s.code.id !== 'cover-image');

  const html = sections.map((s: ISection) => s.text.div).join('');

  const blocksFromHTML = convertFromHTML(html);
  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

  const content = EditorState.createWithContent(state);

  const tags = data.event?.map((x) => {
    return {
      value: x?.id,
      label: '',
    };
  });

  return {
    id: data.id,
    description: data.text?.div,
    title: data.title,
    section: data.section,
    status: data.status,
    language: data.language,
    content,
    date: data.meta?.lastUpdated,
    categoryId: data.subject?.reference?.split('/')[1] ?? '',
    relatesTo: data.relatesTo,
    tags,
    coverImage:
      data.section?.find((x: { code: { id: string } }) => x.code.id === 'cover-image')?.entry?.[0]?.display || '',
  };
};

const requiredText = yup.string().required(t(`common.field-required`));
export const schema = yup.object().shape({
  contentId: requiredText,
  contents: yup
    .array()
    .of(
      yup.object().shape({
        content: yup
          .mixed()
          .required()
          .test('content', t('common.field-required'), (value: any) => {
            const content = value.getCurrentContent();
            const contentLength = content.getPlainText('').trim().length;

            if (contentLength === 0) {
              return false;
            }

            return true;
          }),
        description: yup.string(),
        isSource: yup.boolean(),
        language: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
          })
          .required(),
        languageLabel: yup.string(),
        title: yup.string().required(),
        status: yup.string().required(),
      })
    )
    .required(),
  categoryId: requiredText,
  coverImage: requiredText,
  coverImageSize: yup.number().required(t('common.field-required')).max(4000000, t('content.max-file-size')),
  tags: yup.array().min(1, t('common.field-required')).required(t('common.field-required')),
});

//Please do not delete the commented code below. It is used for future reference.
export const targetLangs = [
  { label: 'German', value: 'de' },
  { label: 'English', value: 'en-GB' },
  // { label: 'Bulgarian', value: 'BG' },
  // { label: 'Czech', value: 'CS' },
  // { label: 'Danish', value: 'DA' },
  // { label: 'Greek', value: 'EL' },
  // { label: 'English (British)', value: 'EN-GB' },
  // { label: 'English (American)', value: 'EN-US' },
  // { label: 'Spanish', value: 'ES' },
  // { label: 'Estonian', value: 'ET' },
  // { label: 'Finnish', value: 'FI' },
  // { label: 'French', value: 'FR' },
  // { label: 'Hungarian', value: 'HU' },
  // { label: 'Indonesian', value: 'ID' },
  // { label: 'Italian', value: 'IT' },
  // { label: 'Japanese', value: 'JA' },
  // { label: 'Korean', value: 'KO' },
  // { label: 'Lithuanian', value: 'LT' },
  // { label: 'Latvian', value: 'LV' },
  // { label: 'Norwegian (Bokmål)', value: 'NB' },
  // { label: 'Dutch', value: 'NL' },
  // { label: 'Polish', value: 'PL' },
  // { label: 'Portuguese', value: 'PT' },
  // { label: 'Portuguese (Brazilian)', value: 'PT-BR' },
  // { label: 'Portuguese (all Portuguese varieties excluding Brazilian Portuguese)', value: 'PT-PT' },
  // { label: 'Romanian', value: 'RO' },
  // { label: 'Russian', value: 'RU' },
  // { label: 'Slovak', value: 'SK' },
  // { label: 'Slovenian', value: 'SL' },
  // { label: 'Swedish', value: 'SV' },
  // { label: 'Turkish', value: 'TR' }
  // { label: 'Ukrainian', value: 'UK' },
  // { label: 'Chinese (simplified)', value: 'ZH' }
];

export const contentEditorSourceLang = { label: 'Deutsch', value: 'de' };

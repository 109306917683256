import {
  TextInput,
  Checkbox,
  Button,
  Group,
  Box,
  PasswordInput,
  Container,
  Title,
  Space,
  Paper,
  Anchor,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { ReactElement, useState } from 'react';
import { AppShell, Logo, useMedplum } from '@medplum/react';
import { useNavigate } from 'react-router-dom';
import { getConfig } from './config';

export const SignupPage = (): ReactElement => {
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const medplum = useMedplum();
  const baseUrl = getConfig().minxliBaseUrl;

  const form = useForm({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      projectName: '',
      password: '',
      termsOfService: false,
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      firstName: (value) => (value?.length > 1 ? null : 'Invalid first name'),
      lastName: (value) => (value?.length > 1 ? null : 'Invalid last name'),
      projectName: (value) => (value?.length > 1 ? null : 'Invalid project name'),
      termsOfService: (value) => (value ? null : 'You should agree to our terms of service'),
      password: (value) => (value?.length > 5 ? null : 'Invalid password'),
    },
  });

  const handleFormSubmit = async function handleFormSubmit(
    values: ReturnType<
      (values: {
        firstName: string;
        lastName: string;
        termsOfService: boolean;
        projectName: string;
        email: string;
        password: string;
      }) => {
        firstName: string;
        lastName: string;
        termsOfService: boolean;
        projectName: string;
        email: string;
        password: string;
      }
    >
  ): Promise<void> {
    setLoading(true);
    setError(null);
    try {
      const res = await fetch(`${baseUrl}project/create`, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const body = await res.json();
      if (body.error) {
        console.log(body.error);
        throw body.error;
      }
      const code = await medplum.startLogin({ email: values.email, password: values.password });
      if (code?.code) {
        await medplum.processCode(code.code);
      }
      setSuccess(true);
      setTimeout(() => {
        // wait a second to show success message, then redirect
        navigate('/');
      }, 1000);
    } catch (e: any) {
      if (e.message === 'Email already exists') {
        setError("E-Mail already exists");
      } else {
        setError("Error while signing up");
      }
    }
    setLoading(false);
  };

  return (
    <AppShell logo={<Logo size={30} />}>
      <div>
        <Container size={480} my={40}>
          {error && (
            <Paper withBorder shadow="md" p={30} mt={30} radius="md" style={{ textAlign: 'center' }}>
              <Title>Error!</Title>
              <Space h={'sm'}></Space>
              <p>{error}</p>
            </Paper>
          )}
          {success && (
            <Paper withBorder shadow="md" p={30} mt={30} radius="md" style={{ textAlign: 'center' }}>
              <Title>Success!</Title>
              <Space h={'sm'}></Space>
              <p>{"Sign up E-Mail sent"}</p>
            </Paper>
          )}
          {!success && (
            <>
              <Space h={'sm'}></Space>
              <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <Box maw={340} mx="auto">
                  <Title>{"Register"}</Title>
                  <form onSubmit={form.onSubmit((values) => handleFormSubmit(values))}>
                    <TextInput
                      withAsterisk
                      label={"E-Mail"}
                      placeholder="your@email.com"
                      {...form.getInputProps('email')}
                    />{' '}
                    <PasswordInput
                      withAsterisk
                      label={"Password"}
                      placeholder="*******"
                      {...form.getInputProps('password')}
                    />
                    <Space h={'md'}></Space>
                    <TextInput
                      withAsterisk
                      label={"First Name"}
                      placeholder="John"
                      {...form.getInputProps('firstName')}
                    />
                    <TextInput
                      withAsterisk
                      label={"Last Name"}
                      placeholder="Doe"
                      {...form.getInputProps('lastName')}
                    />
                    <Space h={'md'}></Space>
                    <TextInput
                      withAsterisk
                      label={"Project Name"}
                      placeholder={"My Project"}
                      {...form.getInputProps('projectName')}
                    />
                    <Space h={'md'}></Space>
                    <Checkbox
                      mt="md"
                      label={
                        <>
                          {"I accept the"}{' '}
                          <Anchor href="https://ovok.com/agb/" target="_blank" inherit>
                            {"terms and conditions"}
                          </Anchor>
                        </>
                      }
                      {...form.getInputProps('termsOfService', {
                        type: 'checkbox',
                      })}
                    />
                    <Space h={'lg'}></Space>
                    <Group mt="md">
                      <Button fullWidth type="submit" loading={isLoading}>
                        {"Submit"}
                      </Button>
                    </Group>
                  </form>
                </Box>
              </Paper>
            </>
          )}
        </Container>
      </div>
    </AppShell>
  );
};

import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { Content } from '../../types/content/contentTypes';
import { QueryKeys } from '../query-keys';
import { mapToResult } from '../../utils/content/contentUtils';
import { useParams } from 'react-router-dom';
import { useMedplum } from '@medplum/react';

export const useGetContentDetail = (options?: UseQueryOptions<Content[], Error>): UseQueryResult<any> => {
  const params = useParams();
  const { id: contentId } = params;
  const medplum = useMedplum();

  return useQuery<Content[], Error>(
    [QueryKeys.GET_CONTENT_DETAIL, contentId],
    async () =>
      medplum
        .searchResources('Composition', {
          _id: contentId,
          _revinclude: 'Composition:related-ref:Composition',
        })
        .then((res) => mapToResult(res?.bundle?.entry?.map((x: any) => x?.resource)) as Content[]),
    {
      ...options,
      enabled: !!contentId,
    }
  );
};

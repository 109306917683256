import React from 'react';
import { useQueryClient } from 'react-query';
import { useCreateUpdateContent } from '../queries/content/useCreateUpdateContent';
import { QueryKeys } from '../queries/query-keys';
import { ContentForm } from './ContentForm';
import { Binary } from '@medplum/fhirtypes';
import { useContentStore } from '../store/content/useContentStore';
import { LoadingOverlay } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { contentEditorSourceLang } from '../utils/content/contentUtils';
import { t } from 'i18next';
import { showNotification } from '@mantine/notifications';

interface Props {}

const CreateContentPage: React.FC<Props> = () => {
  const medplum = useMedplum();
  const queryClient = useQueryClient();
  const values = useContentStore((s) => s.values);
  const resetValues = useContentStore((s) => s.resetValues);

  React.useEffect(() => {
    resetValues();
  }, []);

  const { mutateAsync: createContent, isLoading: isCreateUpdateContentLoading } = useCreateUpdateContent(values, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(QueryKeys.GET_CONTENTS);
      await queryClient.invalidateQueries(QueryKeys.GET_CONTENT_DETAIL, data.id);
      setSelectedLanguage(contentEditorSourceLang);
      showNotification({ color: 'green', message: t('success') });
    },
    onError: (err: any) => {
      console.error(err);
    },
  });

  const [selectedLanguage, setSelectedLanguage] = React.useState<{ label: string; value: string }>(
    contentEditorSourceLang
  );

  const handleUploadCoverImage = async (file: File): Promise<string | undefined> => {
    const binary: Binary = await medplum.createBinary(file, file.name, file.type);

    return binary.url as string;
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      const coverImageUrl = await handleUploadCoverImage(values?.coverImageFile as File);

      await createContent({ coverImageUrl });
    } catch (error) {
      console.error({ error });
    }
  };

  if (isCreateUpdateContentLoading) {
    return <LoadingOverlay visible={isCreateUpdateContentLoading} overlayProps={{ blur: 2 }} />;
  }

  return (
    <ContentForm
      onSubmit={handleSubmit}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
    />
  );
};

export default CreateContentPage;

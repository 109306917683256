import * as React from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useMedplum } from '@medplum/react';
import { Binary } from '@medplum/fhirtypes';
import classes from './draftjseditor.module.css';
interface DraftJsEditorProps {
  language: string;
  editorState: EditorState;
  onEditorStateChange: (content: EditorState, language: string) => void;
}

const DraftJsEditor: React.FC<DraftJsEditorProps> = ({ editorState, onEditorStateChange, language }) => {
  const medplum = useMedplum();

  const _uploadImageCallBack = async (file: any): Promise<{ data: { link: string } } | undefined> => {
    const binary: Binary = await medplum.createBinary(file, file.name, file.type);

    return new Promise((resolve) => {
      resolve({ data: { link: binary?.url as string } });
    });
  };

  return (
    <Editor
      editorState={editorState}
      placeholder="Tell your story..."
      onEditorStateChange={(value: any) => onEditorStateChange(value, language)}
      handlePastedText={() => false}
      wrapperClassName={classes.wrapper}
      editorClassName={classes.editor}
      stripPastedStyles={true}
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'list',
          'textAlign',
          'colorPicker',
          'link',
          'emoji',
          'image',
          'remove',
          'history',
        ],
        inline: { inDropdown: false },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: false },
        history: { inDropdown: false },
        image: {
          uploadCallback: _uploadImageCallBack,
          urlEnabled: true,
          uploadEnabled: true,
        },
        embedded: { hidden: true },
        fontFamily: {
          inDropdown: false,
          options: ['Inter'],
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
        },
        inputAccept:
          'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel',
      }}
    />
  );
};

export default DraftJsEditor;

import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { QueryKeys } from '../query-keys';
import { BundleEntry, List } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';

export const useGetContentCategories = (options?: UseQueryOptions<List[] | undefined, Error>): UseQueryResult<any> => {
  const medplum = useMedplum();
  return useQuery<List[] | undefined, Error>(
    [QueryKeys.GET_CONTENT_CATEGORIES],
    async () =>
      (await medplum.searchResources('List', 'identifier=category'))?.bundle?.entry?.map(
        (x: BundleEntry) => x?.resource as List
      ),
    options
  );
};

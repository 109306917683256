import {
  ActionIcon,
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Grid,
  LoadingOverlay,
  Modal,
  Radio,
  Select,
  Tabs,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { IconApps, IconFolder, IconPlus, IconList, IconFolderOpen, IconSearch, IconTrash } from '@tabler/icons-react';
import React, { useState } from 'react';
import ContentTable from './ContentTable';
import ContentGrid from './ContentGrid';
import { useNavigate } from 'react-router-dom';
import { useGetContentCategories } from '../queries/content/useGetContentCategories';
import { useMedplum } from '@medplum/react';
import { useContentStore } from '../store/content/useContentStore';
import { useCreateContentFolder } from '../queries/content/useCreateContentFolder';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../queries/query-keys';
import { useGetContents } from '../queries/content/useGetContents';
import { ContentStatusType } from '../types/content/contentTypes';
import { useMoveOrDeleteContentsCategory } from '../queries/content/useMoveOrDeleteContentsCategory';
import { showNotification } from '@mantine/notifications';

interface Props {}

const ContentsPage: React.FC<Props> = () => {
  const theme = useMantineTheme();
  const medplum = useMedplum();
  const queryClient = useQueryClient();
  const profile = medplum.getProfile();
  const navigation = useNavigate();
  //Store
  const selectedContents = useContentStore((s) => s.selectedContents);
  const searchText = useContentStore((s) => s.searchText);
  const setSearchText = useContentStore((s) => s.handleChangeSearch);
  const statusValue = useContentStore((s) => s.status);
  const setStatusValue = useContentStore((s) => s.handleChangeStatus);
  //States
  const [folderType, setFolderType] = React.useState<'list' | 'grid'>('grid');
  const [activeTab, setActiveTab] = useState<string | null>('');
  const [isAddFolderModalOpen, setIsAddFolderModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] = useState(false);
  const [selectedCategoryToMoveContentsInto, setSelectedCategoryToMoveContentsInto] = useState<string | null>(null);
  const [checkedOptionCategoryDelete, setCheckedOptionCategoryDelete] = useState<'move-contents' | 'delete-contents'>(
    'move-contents'
  );

  //React Query
  const { data: categoriesData } = useGetContentCategories({
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (resp) => {
      if (resp?.[0]?.id) {
        setActiveTab(resp?.[0]?.id);
      } else {
        setActiveTab('');
      }
    },
  });

  const { mutateAsync: createCategory, isLoading: isCreateCategoryLoading } = useCreateContentFolder({
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKeys.GET_CONTENT_CATEGORIES]);
      setIsAddFolderModalOpen(false);
      setNewCategoryName('');
    },
  });

  const { data: contentsData } = useGetContents({
    categoryId: activeTab ?? '',
    filter: {
      status: ContentStatusType.ALL,
      search: '',
    },
  });

  const handleCreateCategory = async (): Promise<void> => {
    await createCategory({ title: newCategoryName, profile });
  };

  const toggleFolderType = (): void => {
    setFolderType(folderType === 'list' ? 'grid' : 'list');
  };

  const handleRouteCreatePage = (): void => {
    navigation('/admin/contents/new');
  };

  const handleRouteToEditPage = (id: string): void => {
    navigation(`/admin/contents/${id}`);
  };

  const { mutateAsync: moveOrDeleteContentCategory, isLoading: isMoveOrDeleteLoading } =
    useMoveOrDeleteContentsCategory(
      {
        categoryId: activeTab,
        categoryMoveId: selectedCategoryToMoveContentsInto,
        compositions: contentsData ?? [],
        type: checkedOptionCategoryDelete,
      },
      {
        onSuccess: async () => {
          showNotification({ color: 'green', message: 'Success' });
        },
        onSettled: async () => {
          window.location.reload();
        },
      }
    );

  const handleMoveOrDeleteContentsCategories = async (): Promise<void> => {
    await moveOrDeleteContentCategory({});
  };

  const statusArr = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Draft', value: 'draft' },
    { label: 'Inactive', value: 'inactive' },
  ];

  if (isMoveOrDeleteLoading) {
    return <LoadingOverlay visible={isMoveOrDeleteLoading} />;
  }

  return (
    <Card>
      <Grid>
        <Grid.Col span={12}>
          <Center inline p={4} style={{ width: '100%', justifyContent: 'space-between' }}>
            <Center inline>
              <IconFolder size={24} />
              <Text size="xl" style={{ marginLeft: 8, weight: 'bold' }}>
                Contents
              </Text>
            </Center>
            <Center inline>
              <Button variant="filled" leftSection={<IconPlus size={18} />} onClick={handleRouteCreatePage}>
                Create new
              </Button>
            </Center>
          </Center>
        </Grid.Col>
        <Grid.Col span={12}>
          <Center
            inline
            px={12}
            py={10}
            bg={'#F1F3F9'}
            style={{ width: '100%', justifyContent: 'space-between', borderRadius: 5 }}
          >
            <Center inline>
              <TextInput
                leftSection={<IconSearch size={16} />}
                size="sm"
                style={{ minWidth: 300 }}
                mr={8}
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Select
                defaultValue="all"
                value={statusValue}
                onChange={(value) => setStatusValue(value as ContentStatusType)}
                size="sm"
                allowDeselect={false}
                comboboxProps={{ position: 'bottom' }}
                placeholder="Status"
                mr={8}
                data={statusArr}
                style={{ maxWidth: 120 }}
              />
              {selectedContents.length > 0 && (
                <Button color="red" variant="filled" size="sm" leftSection={<IconTrash size={18} />}>
                  Delete
                </Button>
              )}
            </Center>
            <Center inline>
              <Tooltip label={folderType === 'list' ? 'Details' : 'Large icons'}>
                <ActionIcon color="inherit" onClick={toggleFolderType}>
                  {folderType === 'list' ? <IconList /> : <IconApps />}
                </ActionIcon>
              </Tooltip>
            </Center>
          </Center>
        </Grid.Col>
        <Grid.Col span={12}>
          <Center inline style={{ marginBottom: 12 }}>
            <Button
              onClick={() => setIsAddFolderModalOpen(true)}
              size="sm"
              variant="light"
              leftSection={<IconPlus size={18} />}
              mr={12}
            >
              Create new category
            </Button>
            <Button
              onClick={() => setIsDeleteCategoryModalOpen(true)}
              leftSection={<IconTrash size={18} />}
              color="red"
              size="sm"
              variant="light"
            >
              Delete{' '}
              <Text px={4} td="underline" fw={'bold'}>
                {categoriesData?.find((x: any) => x?.id === activeTab)?.title}
              </Text>{' '}
              category
            </Button>
          </Center>
          {categoriesData && (
            <Tabs value={activeTab} onChange={setActiveTab} orientation="vertical">
              <Tabs.List>
                {categoriesData?.map((item: any) => {
                  return (
                    <Tabs.Tab
                      key={item?.id}
                      leftSection={
                        item?.id === activeTab ? <IconFolderOpen color={theme.colors.blue[6]} /> : <IconFolder />
                      }
                      value={item?.id ?? ''}
                    >
                      <Text color={activeTab === item?.id ? theme.colors.blue[6] : 'unset'}>{item?.title}</Text>
                    </Tabs.Tab>
                  );
                })}
              </Tabs.List>
              <Tabs.Panel key={activeTab} value={activeTab as string} p={12} style={{ width: '100%' }}>
                {folderType === 'list' ? (
                  <ContentTable activeTab={activeTab} onClick={handleRouteToEditPage} />
                ) : (
                  <ContentGrid activeTab={activeTab} onClick={handleRouteToEditPage} />
                )}
              </Tabs.Panel>
            </Tabs>
          )}
        </Grid.Col>
      </Grid>

      {/* ADD NEW CATEGORY MODAL */}
      <Modal
        title="Create new category"
        opened={isAddFolderModalOpen}
        onClose={() => setIsAddFolderModalOpen(false)}
        closeOnClickOutside
        centered
        size="md"
      >
        <Flex direction="column">
          <Center my={18}>
            <TextInput
              size="md"
              style={{ width: '100%' }}
              label="Category name"
              value={newCategoryName}
              placeholder="Give a name to your category"
              onChange={(e) => setNewCategoryName(e.target.value)}
            />
          </Center>
          <Flex align="center" justify="flex-end" direction="row" mt={18}>
            <Button variant="outline" onClick={() => setIsAddFolderModalOpen(false)}>
              Cancel
            </Button>
            <Button
              loading={isCreateCategoryLoading}
              onClick={handleCreateCategory}
              disabled={!newCategoryName}
              variant="filled"
              bg={theme.primaryColor}
              ml={8}
            >
              Create
            </Button>
          </Flex>
        </Flex>
      </Modal>

      {/* DELETE CATEGORY MODAL */}
      <Modal
        title="Delete category"
        opened={isDeleteCategoryModalOpen}
        onClose={() => setIsDeleteCategoryModalOpen(false)}
        closeOnClickOutside
        centered
        size={contentsData?.length > 0 ? 'xl' : 'sm'}
      >
        <Divider />
        <Flex direction="column" my={12}>
          {contentsData?.length > 0 ? (
            <Flex direction="column">
              <Text>
                This category has contents. Would you like to move all contents or delete all contents and the category?
              </Text>
              <Flex my={12} direction="row" style={{ width: '100%' }}>
                <Radio
                  label="Move contents"
                  checked={checkedOptionCategoryDelete === 'move-contents'}
                  onChange={() => setCheckedOptionCategoryDelete('move-contents')}
                  style={{ marginRight: 12 }}
                />
                <Radio
                  label="Delete contents"
                  checked={checkedOptionCategoryDelete === 'delete-contents'}
                  onChange={() => setCheckedOptionCategoryDelete('delete-contents')}
                />
              </Flex>
              {checkedOptionCategoryDelete === 'move-contents' && (
                <Select
                  size="sm"
                  comboboxProps={{ position: 'bottom' }}
                  data={categoriesData?.map((x: any) => {
                    return {
                      label: x?.title,
                      value: x?.id,
                    };
                  })}
                  label="Move contents to"
                  onChange={(val) => setSelectedCategoryToMoveContentsInto(val)}
                />
              )}
              <Flex align="center" justify="flex-end" direction="row" mt={18}>
                <Button variant="outline" onClick={() => setIsDeleteCategoryModalOpen(false)}>
                  Cancel
                </Button>
                <Button
                  loading={isMoveOrDeleteLoading}
                  onClick={handleMoveOrDeleteContentsCategories}
                  variant="filled"
                  bg={theme.primaryColor}
                  ml={8}
                >
                  {checkedOptionCategoryDelete === 'move-contents'
                    ? 'Move contents & delete category'
                    : 'Delete category & contents'}
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Flex w={'100%'} direction="column">
              <Text>
                Are you sure to delete {categoriesData?.find((x: any) => x?.id === activeTab)?.title} {` `}
                category?
              </Text>
              <Flex align="center" justify="flex-end" direction="row" mt={18}>
                <Button variant="outline" onClick={() => setIsAddFolderModalOpen(false)}>
                  Cancel
                </Button>
                <Button variant="filled" bg={theme.primaryColor} ml={8}>
                  Delete category
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Modal>
    </Card>
  );
};

export default ContentsPage;

import * as React from 'react';
import _ from 'lodash';
import { ContentFormType, IContents } from '../types/content/contentTypes';
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Flex,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { TagSelection } from './TagSelection';
import { LanguageStatusSelection } from './LanguageStatusSelection';
import { CategorySelection } from './CategorySelection';
import { useContentStore } from '../store/content/useContentStore';

interface ContentInfoCardProps {
  tagsData?: { value: string; label: string }[];
  isSourceLanguage: boolean;
  selectedLanguage: { label: string; value: string };
  handleCreateContent: () => void;
  handleRemoveLanguage: () => void;
  publishDisabled?: boolean;
}

export const ContentInfoCard: React.FC<ContentInfoCardProps> = ({
  isSourceLanguage,
  selectedLanguage,
  handleCreateContent,
  handleRemoveLanguage,
  publishDisabled,
}) => {
  const theme = useMantineTheme();
  const [state, setState] = React.useState({
    isTitleFocused: false,
    isDescFocused: false,
  });

  const values = useContentStore((s) => s.values);
  const setValues = useContentStore((s) => s.handleSetValues);

  const contentTitle = React.useMemo(
    () => values.contents?.find((x) => x?.language?.value === selectedLanguage?.value)?.title,
    [values.contents, selectedLanguage]
  );
  const contentDescription = React.useMemo(
    () => values?.contents?.find((x) => x?.language?.value === selectedLanguage?.value)?.description,
    [values.contents, selectedLanguage]
  );
  const contentStatus = React.useMemo(
    () => values?.contents?.find((x) => x?.language?.value === selectedLanguage?.value)?.status,
    [values.contents, selectedLanguage]
  );

  const contentIndex = React.useMemo(
    () => values?.contents?.findIndex((x) => x?.language?.value === selectedLanguage?.value),
    [values.contents, selectedLanguage]
  );

  const handleFocus = (name: keyof typeof state): void => {
    setState((prev) => ({ ...prev, [name]: true }));
  };

  const handleBlur = (name: keyof typeof state): void => {
    setState((prev) => ({ ...prev, [name]: false }));
  };

  const handleChangeCategory = (value: string | null): void => {
    // setFieldValue('categoryId', value);
    setValues({ ...values, categoryId: value ?? '' });
  };

  const handleChangeStatus = (value: IContents['status']): void => {
    const theContents = _.cloneDeep(values.contents);
    const findIndex = theContents.findIndex((x) => x.language.value === selectedLanguage.value);
    theContents[findIndex].status = value;
    setValues({ ...values, contents: theContents });
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const theContents = _.cloneDeep(values.contents);
    theContents[contentIndex].title = value;
    setValues({ ...values, contents: theContents });
  };

  const handleChangeDesc = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const theContents = _.cloneDeep(values.contents);
    theContents[contentIndex].description = value;
    setValues({ ...values, contents: theContents });
  };

  return (
    <Card shadow="md" withBorder style={{ overflow: 'visible' }}>
      <Center mb={4} inline style={{ width: '100%', justifyContent: 'space-between' }}>
        <Flex align="center" justify="flex-start" style={{ width: '100%' }}>
          {!state.isTitleFocused ? (
            <Title
              order={3}
              onClick={() => {
                handleFocus('isTitleFocused');
              }}
            >
              {contentTitle}
            </Title>
          ) : (
            <Box style={{ width: '40%', minWidth: '30%', maxWidth: '50%' }}>
              <TextInput
                autoFocus
                value={contentTitle}
                onChange={handleChangeTitle}
                onBlur={() => handleBlur('isTitleFocused')}
                name={`contents[${contentIndex}].title`}
              />
            </Box>
          )}
          <Tooltip label="Edit title">
            <ActionIcon ml={8} onClick={() => handleFocus('isTitleFocused')} color={'primary'}>
              <IconPencil size={18} />
            </ActionIcon>
          </Tooltip>
        </Flex>
        <Flex align="center" justify="center">
          {!isSourceLanguage && (
            <Tooltip label="Remove language">
              <ActionIcon mr={8} onClick={handleRemoveLanguage} size="medium">
                <IconTrash color={theme.colors.red[6]} fontSize="inherit" />
              </ActionIcon>
            </Tooltip>
          )}
          {contentStatus && (
            <LanguageStatusSelection
              isCreating
              value={contentStatus}
              onChange={(val: IContents['status']) => handleChangeStatus(val)}
            />
          )}
          <Button
            variant="filled"
            size="sm"
            ml={8}
            bg={theme.colors.green[6]}
            onClick={handleCreateContent}
            disabled={publishDisabled}
          >
            {values.formType === ContentFormType.UPDATE ? 'Update' : 'Publish'}
          </Button>
        </Flex>
      </Center>
      <Flex align="center" justify="space-between" py={4}>
        <Center inline>
          {!state.isDescFocused ? (
            <Text
              variant="text"
              size="md"
              onClick={() => {
                handleFocus('isDescFocused');
              }}
            >
              {contentDescription}
            </Text>
          ) : (
            <Box>
              <TextInput
                variant="standard"
                autoFocus
                value={contentDescription}
                onChange={handleChangeDesc}
                onBlur={() => handleBlur('isDescFocused')}
                name={`contents[${contentIndex}].description`}
              />
            </Box>
          )}
          <Tooltip label="Edit description">
            <ActionIcon ml={8} onClick={() => handleFocus('isDescFocused')} color={'primary'}>
              <IconPencil size={18} />
            </ActionIcon>
          </Tooltip>
        </Center>

        <Center inline>
          <TagSelection
            disabled={!isSourceLanguage}
            errorText={''}
            // errorText={errors.tags && touched.tags ? (errors.tags as string) : ''}
          />

          <CategorySelection
            disabled={!isSourceLanguage}
            value={values.categoryId}
            onChange={handleChangeCategory}
            errorText={''}
            // errorText={errors.categoryId && touched.categoryId ? errors.categoryId : ''}
          />
        </Center>
      </Flex>
    </Card>
  );
};
